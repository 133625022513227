// Entry point for the build script in your package.json

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { resolvePage } from '../pages';

import { createInertiaApp } from '@inertiajs/inertia-react';
import { InertiaProgress } from '@inertiajs/progress';

InertiaProgress.init();

createInertiaApp({
  resolve: resolvePage,
  setup({ el, App, props }) {
    const root = ReactDOM.createRoot(el);
    root.render(<App {...props} />);
  },
});
