/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import MainLayout from '@/Layouts/Body';
const pages = import.meta.glob('./Pages/**/*.tsx');

export async function resolvePage(name: string) {
  const page = pages[`./Pages/${name}.tsx`];

  if (!page)
    throw new Error(`Unknown page ${name}. Is it located under Pages with a .tsx extension?`);

  // @ts-ignore
  const pageToRender = (await page()).default;

  const layout = (childPage: React.ReactNode) => {
    return <MainLayout>{childPage}</MainLayout>;
  };

  pageToRender.layout = pageToRender.layout || layout;

  return pageToRender;
}
